import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import _isMatch from 'underscore/modules/isMatch';
import { useText } from '@base/core';

import { useURLBuilder } from './urlBuilder';
import { getResponseErrorMessage } from '../utils/utils';

// import {
//   AccessServiceContext,
//   LanguageServiceContext,
//   LoginModalStateServiceContext,
// } from '../services';

// import { languages, DEFAULT_LANGUAGE } from '../configs/i18n';

// Should use it everywhere instead useNavigate();
export function useLocaleNavigate() {
  const urlBuilder = useURLBuilder();
  // const access = useContext(AccessServiceContext);
  // const loginModalState = useContext(LoginModalStateServiceContext);
  // const currentLangId = useContext(LanguageServiceContext);
  const navigate = useNavigate();

  return (route, options = {}) => {
    return navigate(urlBuilder(route, options.params, options.search), options);
    // if (typeof route.access === 'object') {
    //   if (_isMatch(access, route.access)) {
    //     return navigate(urlBuilder(route), options.params, options.search);
    //   } else {
    //     loginModalState.open(route);
    //   }
    // } else {
    //   return navigate(urlBuilder(route), options.params, options.search);
    // }
  };
  // navigate(`/${currentLangId}/${route.path !== '/' ? route.path : ''}`, options);
}

// export function useAuthorizedNavigate({ onRestrictedRoute } = {}) {
//   // const currentLangId = useContext(LanguageServiceContext);
//   const urlBuilder = useURLBuilder();
//   const access = useContext(AccessServiceContext);
//   const loginModalState = useContext(LoginModalStateServiceContext);
//   // console.log('useAuthorizedNavigate:prepare', currentLangId, access);
//   const navigate = useNavigate();
//   return (route, options) => {
//     // console.log('useAuthorizedNavigate:call', route, currentLangId, access);
//     if (typeof route === 'string') {
//       return navigate(urlBuilder(route), options);
//       // return navigate(`/${currentLangId}${route}`, options);
//     }
//     if (typeof route === 'object') {
//       const fullRoute = urlBuilder(route); //`/${currentLangId}/${route.path !== '/' ? route.path : ''}`;
//       if (typeof route.access === 'object') {
//         if (_isMatch(access, route.access)) {
//           return navigate(fullRoute, options);
//         } else {
//           if (typeof onRestrictedRoute === 'function') {
//             const newRoute = onRestrictedRoute(route);
//             if (typeof newRoute === 'object') {
//               return navigate(urlBuilder(newRoute), options);
//             }
//           } else if (onRestrictedRoute === 'loginModal') {
//             loginModalState.open(route);
//           }
//           return null;
//         }
//       }
//     }
//   };
// }

// Should use it to switch to another app (e.g. public => app-web or vice versa).
export function useLocaleSwitchApp() {
  const urlBuilder = useURLBuilder();
  // let currentLangId = useContext(LanguageServiceContext);
  // if (!languages.includes(currentLangId)) {
  //   currentLangId = DEFAULT_LANGUAGE;
  // }
  return (route, search) => {
    document.location.assign(urlBuilder(route, {}, search));
    // const paramString = Object.entries(params)
    //   .map(([key, value]) => `${key}=${value}`)
    //   .join('&');
    // const preparedParamString = paramString ? `?${paramString}` : '';
    // document.location.assign(
    //   `/${currentLangId}/${route.path !== '/' ? route.path : ''}${preparedParamString}`
    // );
  };
}

// export function useSwitchLanguage() {
//   const currentLangId = useContext(LanguageServiceContext);
//   const navigate = useNavigate();
//   const location = useLocation();
//   return (langId) => {
//     if (languages.includes(langId)) {
//       const otherLangRoute = location.pathname.replace(currentLangId, langId) + location.search;
//       navigate(otherLangRoute);
//     }
//   };
// }

export function useResponseErrorMessage() {
  const text = useText();
  return (response) => {
    const message = getResponseErrorMessage(response);
    return text(message, {}, message);
  };
}
