const i18n = {
  // Array of language identifiers, also used as URL routes.
  // First language in array is used as default language.
  languages: ['ru'],

  // Language names for displaying in application.
  // Keys of this object must be identical to array 'languages' described above.
  // For iterating over the object should use array 'languages' described above,
  //   it allows to keep unite language order.
  languageNames: {
    en: 'Ru',
  },

  supportedLocales: ['Ru-ru', 'ru'],
};

export default i18n;
