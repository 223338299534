export default {
  'about-fund': { dialog: 'CharityProjectDialog' },
  'inactive-account': { dialog: 'InactiveAccountDialog' },
  faq: { dialog: 'FAQDialog' },
  login: { dialog: 'LoginDialog' },
  'contact-us': { dialog: 'ContactUsDialog' },
  signup: { dialog: 'SignUpDialog' },
  thanks: { dialog: 'PostContactUsDialog' },
  'password-recovery': { dialog: 'PasswordRecoveryDialog' },
  stop_reminder: { dialog: 'StopReminderDialog' },
  postsignup: { dialog: 'PostRegistrationDialog' },
  unsubscribe: { dialog: 'UnsubscribeDialog' },
};
