import React from 'react';
import { Routes, Route, Navigate, useParams, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

import { getBrowserLocales } from '../utils/utils';
import useService from '../core/ServiceRegistry';

const DEFERRED_ROUTE_KEY = 'deferredRoute';

export function setDeferredRoute(route) {
  sessionStorage.setItem(DEFERRED_ROUTE_KEY, JSON.stringify(route));
}

export function resetDeferredRoute() {
  sessionStorage.removeItem(DEFERRED_ROUTE_KEY);
}

let localeTags = {};

function uploadDateLocales(languages, supportedLocales) {
  if (Object.keys(localeTags).length > 0) return;
  const browserLocales = getBrowserLocales();
  localeTags = browserLocales.reduce((prev, locale) => {
    let localeTagArray = locale.split('-');
    if (localeTagArray.length === 1) {
      return prev;
    }
    if (prev[localeTagArray[0]]) {
      return prev;
    }
    if (localeTagArray.length > 2) {
      localeTagArray = localeTagArray.slice(0, 1);
    }
    const preparedLocale = localeTagArray.join('-').toLowerCase();
    if (supportedLocales.includes(preparedLocale)) {
      prev[localeTagArray[0]] = preparedLocale;
    }
    return prev;
  }, {});

  languages.forEach((langId) => {
    if (!localeTags[langId]) {
      localeTags[langId] = langId;
    }
  });
}

function I18nContent({ language, children }) {
  const { langId } = useParams();
  const { pathname, search } = useLocation();
  const { current, disableUrlPatching } = language;

  if (language.trySet(langId)) {
    dayjs.locale(localeTags[langId]);
    return children;
  }
  let defaultLangRoute = `${disableUrlPatching ? '' : current}${pathname}${search}`;
  if (defaultLangRoute[0] !== '/') {
    defaultLangRoute = `/${defaultLangRoute}`;
  }
  return <Navigate replace to={defaultLangRoute} />;
}

function buildLangRoutes(language, children, search) {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Navigate replace to={`/${language.current}/${search}`} />} />
        <Route
          path=":langId/*"
          element={<I18nContent language={language}>{children}</I18nContent>}
        />
      </Route>
    </Routes>
  );
}

function buildPlainRoutes(language, children, search) {
  return (
    <Routes>
      <Route element={<I18nContent language={language}>{children}</I18nContent>} />
    </Routes>
  );
}

export default function I18nRouter({ children }) {
  const {
    i18n: { languages, supportedLocales },
  } = useService('settings');
  const language = useService('language');
  const { search } = useLocation();
  const { current, disableUrlPatching } = language;

  uploadDateLocales(languages, supportedLocales);

  return disableUrlPatching
    ? buildPlainRoutes(language, children, search)
    : buildLangRoutes(language, children, search);
}
