import React from 'react';

import { injectedHook, useText } from '@base/core';

export default injectedHook(
  {
    name: 'useDetectPredefinedNotifications',
    services: ['notifier'],
  },
  function useDetectPredefinedNotifications({ services }) {
    const { notifier } = services;
    const text = useText();
    return () => {
      const searchParams = new URLSearchParams(window.location.search);
      // const amount = searchParams.get('amount') || 0;
      // if (amount) {
      //   const message = text('survey.finished.notification__credited_amount', { value: amount });
      //   notifier.push(message);
      // }
    };
  }
);
