import React, { useState } from 'react';
import clsx from 'clsx';

import { injectedComponent } from '@base/core';

export default injectedComponent(
  {
    name: 'StyledSelect',
    services: ['assets'],
    styles: ['self'],
  },
  function StyledSelect({
    options,
    currentOption,
    imageBaseUrl,
    onValueChange,
    placeholder,
    fullWidth,
    scrolled,
    currentItemClass,
    disabled,
    drawIcon,
    services: { assets },
    styles: { self: selfStyles },
  }) {
    const [isOpen, toggleSelect] = useState(false);

    const handleSelectToggle = () => {
      if (disabled) return;
      toggleSelect(!isOpen);
    };

    const handleOptionSelect = (e) => {
      if (disabled) return;
      onValueChange(e.target.dataset['value']);
      toggleSelect(!isOpen);
    };

    return (
      <div
        className={clsx(selfStyles.select, {
          [selfStyles.select_show]: isOpen,
          [selfStyles.select_fullWidth]: fullWidth,
        })}
      >
        <button
          type="button"
          className={clsx(selfStyles.select__toggle, currentItemClass, {
            [selfStyles.select__toggle__open]: isOpen,
          })}
          data-select="toggle"
          data-index={currentOption?.value || -1}
          onClick={handleSelectToggle}
        >
          {currentOption ? (
            <>
              {drawIcon && (
                <img
                  className={selfStyles.flagIcon}
                  src={assets.makeImagePath(imageBaseUrl + currentOption.icon)}
                />
              )}
              {currentOption.name}
            </>
          ) : (
            placeholder
          )}
        </button>
        <div
          className={clsx(selfStyles.select__dropdown, {
            [selfStyles.select__dropdown__scrolled]: scrolled,
          })}
          onClick={handleOptionSelect}
        >
          <ul className={selfStyles.select__options}>
            {options.map(({ value, name, icon }) => (
              <li
                key={value}
                className={clsx(selfStyles.select__option, {
                  [selfStyles.select__option_selected]: currentOption?.value === value,
                })}
                data-value={value}
              >
                {drawIcon && (
                  <img
                    className={selfStyles.flagIcon}
                    src={assets.makeImagePath(imageBaseUrl + icon)}
                  />
                )}
                {name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
);
