import React from 'react';

import { injectedComponent } from '@base/core';
import clsx from 'clsx';

export default injectedComponent(
  {
    name: 'UserAvatar',
    components: ['DefaultAvatar'],
    styles: ['avatar'],
  },
  function UserAvatar({
    src,
    initials,
    classes,
    components: { DefaultAvatar },
    styles: { avatar: avatarStyles },
  }) {
    return (
      <div className={clsx(avatarStyles.userAvatar, classes)}>
        <DefaultAvatar initials={initials} />
      </div>
    );
  }
);
