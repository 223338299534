// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.Ydb2lPkUhXXp1WYeW0w0 {\n  margin-top: 10px;\n  margin-bottom: 34px;\n}\n", "",{"version":3,"sources":["webpack://./packages/moyopros/shared/styles/components/PostContactUsDialog.module.css"],"names":[],"mappings":";AACA;EACE,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":["\n.hand {\n  margin-top: 10px;\n  margin-bottom: 34px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hand": "Ydb2lPkUhXXp1WYeW0w0"
};
export default ___CSS_LOADER_EXPORT___;
