import React, { useEffect } from 'react';

import { injectedComponent } from '@base/core';

export default injectedComponent(
  {
    name: 'CookieAgreement',
    services: ['notifier', 'settings'],
  },
  function CookieAgreement({
    notificaionContent,
    onAgreement,
    services: {
      notifier,
      settings: {
        keys: { cookiesAgreementKey },
      },
    },
    diSettings,
  }) {
    const handleAgreeCookies = () => {
      if (typeof onAgreement === 'function') {
        onAgreement();
      } else {
        localStorage.setItem(cookiesAgreementKey, true);
      }
    };

    useEffect(() => {
      const cookiesAgreement = localStorage.getItem(cookiesAgreementKey);
      if (!cookiesAgreement) {
        const id = notifier.makeId();
        notifier.push(notificaionContent(id), {
          id,
          handler: handleAgreeCookies,
          timeless: true,
          ...(diSettings.notification || {}),
        });
      } else {
        typeof onAgreement === 'function' && onAgreement(cookiesAgreement);
      }
    }, []);

    return null;
  }
);
