import React from 'react';
import clsx from 'clsx';

import { injectedComponent, Text } from '@base/core';

export default injectedComponent(
  {
    name: 'PostContactUsDialog',
    services: ['dialog'],
    styles: ['dialog', 'targetDialog', 'button', 'self'],
  },
  function PostContactUsDialog({
    services: { dialog },
    styles: {
      dialog: dialogStyles,
      targetDialog: targetDialogStyles,
      button: buttonStyles,
      self: selfStyles,
    },
  }) {
    const handleOK = () => {
      dialog.close();
    };
    return (
      <div className={clsx(targetDialogStyles.dialog)}>
        <div className={selfStyles.hand}>
          <img src="/public/images/handSmall.svg"></img>
        </div>
        <h3 className={dialogStyles.dialogTitle}>
          <Text>dialog.thanks.title</Text>
        </h3>
        <p className={dialogStyles.description}>
          <Text>dialog.thanks.description</Text>
        </p>
        <form className={dialogStyles.form}>
          <button
            type="button"
            className={clsx(buttonStyles.primaryBtn, buttonStyles.primaryBtn__medium)}
            onClick={handleOK}
          >
            <Text>dialog.thanks.button__ok</Text>
          </button>
        </form>
      </div>
    );
  }
);
