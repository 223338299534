import React from 'react';
import clsx from 'clsx';

import { injectedComponent } from '@base/core';

export default injectedComponent(
  {
    name: 'PopUpNotification',
    styles: ['popup'],
  },
  function PopUpNotification({
    id,
    content,
    fade,
    handler,
    onClose,
    notificationStyle,
    hideButton,
    styles: { popup: popupStyles },
  }) {
    const handleClick = () => {
      typeof handler === 'function' && handler();
      onClose(id);
    };

    return (
      <div
        className={clsx(popupStyles.notification, {
          [popupStyles.notificationFade]: fade,
        })}
      >
        <span className={popupStyles.text} style={notificationStyle || {}}>
          {content}
        </span>
        {!hideButton && (
          <button className={popupStyles.button} onClick={handleClick}>
            Ok
          </button>
        )}
      </div>
    );
  }
);
