import React from 'react';
import clsx from 'clsx';

import { injectedComponent, Text } from '@base/core';

export default injectedComponent(
  {
    name: 'CharityProjectDialog',
    services: ['settings', 'dialog', 'assets'],
    styles: ['dialog', 'targetDialog', 'button', 'self'],
  },
  function CharityProjectDialog({
    services: {
      assets,
      dialog,
      settings: {
        project: { charity },
      },
    },
    styles: {
      dialog: dialogStyles,
      targetDialog: targetDialogStyles,
      button: buttonStyles,
      self: selfStyles,
    },
  }) {
    if (!dialog.param) {
      return dialog.close();
    }

    const [countryId, projectIndex] = dialog.param.split('-');
    if (!countryId || !projectIndex) {
      return dialog.close();
    }

    const countryProjects = charity[countryId];
    if (!countryProjects) {
      return dialog.close();
    }

    const project = countryProjects[projectIndex];
    if (!project) {
      return dialog.close();
    }

    const { image, title, goal, raised, donations, link, summary } = project;

    return (
      <div className={clsx(targetDialogStyles.dialog, selfStyles.dialog)}>
        <h3 className={clsx(dialogStyles.dialogTitle, selfStyles.dialogTitle)}>{title}</h3>
        <img
          className={selfStyles.projectImage}
          src={assets.makeImagePath('charity/' + image)}
        ></img>
        <div className={clsx(selfStyles.details)}>
          <span>
            {raised} funded of {goal}
          </span>
          <span>{donations} donaters</span>
        </div>
        <p className={selfStyles.description}>{summary}</p>
        <a
          className={clsx(
            buttonStyles.primaryBtn,
            buttonStyles.primaryBtn__medium,
            selfStyles.readMore
          )}
          href={link}
          target="_blank"
        >
          Read more at GlobalGiving
        </a>
      </div>
    );
  }
);
