import React from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';

import { injectedComponent } from '@base/core';

export default injectedComponent(
  {
    name: 'PopUpContainer',
    services: ['notifier'],
    components: ['PopUpNotification'],
    styles: ['popup'],
  },
  function PopUpContainer({
    rootElement,
    services: { notifier },
    components: { PopUpNotification },
    styles: { popup: popupStyles },
  }) {
    const handleClose = (id) => notifier.close(id);
    const popups = (
      <div
        className={clsx(popupStyles.popupContainer, {
          [popupStyles.topPopupContainer]: notifier.topPosition,
        })}
      >
        {notifier.stack.map(({ id, content, fade, handler, hideButton, style }) => (
          <PopUpNotification
            key={id}
            id={id}
            content={content}
            notificationStyle={style}
            fade={fade}
            handler={handler}
            hideButton={hideButton}
            onClose={handleClose}
          />
        ))}
      </div>
    );

    return ReactDOM.createPortal(popups, rootElement);
  }
);
