export default [
  {
    image: 'ildarauto.jpg',
  },
  {
    image: 'sanishow.jpg',
  },
  {
    image: 'slovopatsana.jpg',
  },
  {
    image: 'vasyanasene.jpg',
  },
  {
    image: 'KIRILLSARYCHEV.jpg',
  },
];
