export function inIFrame() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export const deepMerge = (target = {}, source) => {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object)
      Object.assign(source[key], deepMerge(target[key], source[key]));
  }
  // Join `target` and modified `source`
  Object.assign(target || {}, source);
  return target;
};

export function getBrowserLocales() {
  return navigator.languages && navigator.languages.length
    ? navigator.languages
    : [navigator.language];
}

export function getResponseErrorMessage(response) {
  const { data } = response;
  if (!data) return response.message;
  if (data.message) return data.message;
  if (Array.isArray(data) && data.length && data[0].message) {
    return data[0].message;
  }
  return response.message;
}

export function isInsideTgMiniApp() {
  return !!window.TelegramWebviewProxy;
}

export function checkCard(cardString) {
  const card = cardString.replace(/ /g, '');
  // console.log(cardString);
  // console.log(card);
  // if (card.length !== 16) {
  //   throw new Error('Card must have 16 digits');
  // }

  let check = [];
  for (let i = 0; i < card.length; i++) {
    /* Checks the 2 1 position */
    if (i % 2 === 0) {
      let result = card.charAt(i) * 2;
      /* Checks to see if the result of * 2 is a two digit */
      if (result > 9) {
        /* Splits the two digit into two single values and then adds them together */
        check.push(parseInt(result.toString().charAt(0)) + parseInt(result.toString().charAt(1)));
      } else {
        /* Push the value because its less then 10 */
        check.push(parseInt(result));
      }
    } else {
      check.push(parseInt(card.charAt(i)));
    }
  }
  /* Sums the check array */
  let checked = check.reduce((accumulator, a) => {
    return accumulator + a;
  }, 0);

  console.log('checkCard:', checked % 10 ? false : true);
  /* Checks if the value ends in a zero */
  return checked % 10 ? false : true;
}
