export default {
  AccessServiceProvider: {
    self: require('@base/services/AccessService').default,
  },
  DialogServiceProvider: {
    self: require('@base/services/DialogService').default,
  },
  NotifierServiceProvider: {
    self: require('@base/services/NotifierService').default,
  },
};
