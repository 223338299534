import React from 'react';
import _template from 'underscore/modules/template';
import useService from './ServiceRegistry';

export function useText() {
  const language = useService('language');
  const dictionary = window.i18n[language.current];
  if (!dictionary) {
    console.error('No dictionary for language', language.current);
    return '???';
  }
  return (sourceKey, params = {}, defaultValue = '???') => {
    const key = params.variant ? sourceKey + params.variant : sourceKey;
    let text = dictionary && dictionary[key];
    if (!text) {
      console.error('No translation for key', key);
      return defaultValue;
    }
    if (Object.keys(params).length) {
      const template = _template(text, { interpolate: /\%(.+?)\%/g });
      text = template(params);
    }
    if (params.asHtml) {
      const Container = params.container || 'span';
      text = <Container dangerouslySetInnerHTML={{ __html: text }}></Container>;
    }
    return text;
  };
}

export function Text({ children, ...params }) {
  const text = useText();
  return text(children, params);
}
