import React, { useEffect } from 'react';

import { injectedComponent } from '@base/core';

import {
  UTM_SOURCE_KEY,
  UTM_SOURCE_VALUE,
  MVF_NETWORK_SRC_VALUE,
  MVF_TRANSACTION_SESSION_KEY,
  MVF_TRANSACTION_PARAM,
} from '../../../shared/configs/constants';

const PRODUCTION = 'production';

export default injectedComponent(
  {
    name: 'MVFPostback',
  },
  function MVFPostback() {
    useEffect(() => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      const onProduction = process.env.PLATFORM === PRODUCTION || params.platform === PRODUCTION;
      if (!onProduction) {
        return;
      }
      const { network_src } = params;
      const utmSource = params[UTM_SOURCE_KEY];
      const transactionId = params[MVF_TRANSACTION_PARAM];
      console.log(network_src, utmSource, transactionId);
      if (
        network_src !== MVF_NETWORK_SRC_VALUE ||
        utmSource !== UTM_SOURCE_VALUE ||
        !transactionId
      ) {
        return sessionStorage.removeItem(MVF_TRANSACTION_SESSION_KEY);
      }
      sessionStorage.setItem(MVF_TRANSACTION_SESSION_KEY, transactionId);
    }, []);

    return null;
  }
);
