import React from 'react';
import clsx from 'clsx';

import { injectedComponent, Text } from '@base/core';

export default injectedComponent(
  {
    name: 'InactiveAccountDialog',
    styles: ['dialog', 'targetDialog'],
  },
  function InactiveAccountDialog({
    styles: { dialog: dialogStyles, targetDialog: targetDialogStyles },
  }) {
    return (
      <div className={clsx(targetDialogStyles.dialog)}>
        <p className={dialogStyles.description}>
          <Text>dialog.inactive_account.text__message</Text>
        </p>
      </div>
    );
  }
);
