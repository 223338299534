import React from 'react';

import Service from '../core/Service';
import ServiceProvider from '../core/ServiceProvider';
import { deepMerge } from '../utils/utils';

const DEFAULT_API_TOKEN_HEADER_KEY = 'community_token';

/**
 * @typedef RequestSettings
 * @type {object}
 *
 * @property {string | function} [path]
 * @property {object} [params] - object with params for path.
 * @property {string} [method] - 'POST', 'GET', etc.
 * @property {object} [payload] - payload data (inside 'payload' field)
 * @property {object} [options] -
 * @property {string = 'success'} [responseType] - 'success', 'full'
 * @property {object} [mock] - mocked response
 *
 */

export class ApiService extends Service {
  // properties
  tokenHeader = null;
  token = null;
  counter = 0;
  version = 'web-' + process.env.APP_VERSION;

  initState(dependencies) {
    const { apiConfig, endpoints } = dependencies;
    this.baseUrl = apiConfig.baseUrl;
    this.tokenTTL = dependencies.tokenTTL;
    this.tokenHeader = dependencies.tokenHeader;
    // Temporary hack -> need to remove after solving process.env.PLATFORM
    const tokenHeaderSessionKey = sessionStorage.getItem('tokenHeaderKey');
    const currentTokenHeader = tokenHeaderSessionKey || this.tokenHeader;

    if (process.env.NODE_ENV === 'development') {
      this.mockCounters = {};
    }
    const proto = Object.getPrototypeOf(this);
    Object.keys(endpoints).forEach((eKey) => {
      const endpoint = endpoints[eKey];
      const endpointSettings =
        typeof endpoint === 'string' ? { path: endpoint, method: 'POST' } : endpoint;
      proto[eKey] = function (/** @type {RequestSettings} */ requestSettings = {}) {
        const endpointSettingsCopy = deepMerge({}, endpointSettings);
        const { path, params, method, payload, responseType, mock, options } = deepMerge(
          endpointSettingsCopy,
          requestSettings
        );
        const pathString = params ? path(params) : path;
        const preparedPayload = payload && { payload };
        return this.$request(
          this,
          this.baseUrl + pathString,
          method,
          preparedPayload,
          responseType,
          mock,
          options,
          eKey,
          currentTokenHeader
        );
      };
    });
  }

  prepareState(dependencies, prevService, newState) {
    super.prepareState(dependencies, prevService, newState);
    this.counter += 1;
    this.ts = Date.now();
  }

  matchState() {
    return false;
  }

  $request(
    app,
    path,
    method,
    payload,
    responseType = 'success',
    mock,
    { mode, credentials, noAuthToken, redirect, authToken, throwMock } = {},
    eKey,
    tokenHeader
  ) {
    if (process.env.NODE_ENV === 'development') {
      if (mock) {
        console.groupCollapsed(`>>> Mocked request '${eKey}'`);
        let response;
        if (typeof mock === 'function') {
          if (!app.mockCounters[eKey]) {
            app.mockCounters[eKey] = 0;
          }
          console.log('mock counter:', ++app.mockCounters[eKey]);
          response = mock(app.mockCounters[eKey]);
        } else {
          response = mock;
        }
        console.log('path:', path);
        console.log('method:', method);
        console.log('payload:', payload);
        console.log('mocked response:', response);
        console.groupEnd();
        return throwMock ? Promise.reject(response) : Promise.resolve(response);
      }
    }
    const defaultHeaders = {
      'AL-Version': this.version,
    };
    return fetch(path, {
      method,
      body: payload ? JSON.stringify(payload) : void 0,
      credentials: credentials || 'include',
      mode: mode || 'cors',
      redirect,
      ...(!noAuthToken
        ? {
            headers: {
              ...defaultHeaders,
              [tokenHeader || DEFAULT_API_TOKEN_HEADER_KEY]: authToken || app.token,
            },
          }
        : {
            headers: {
              ...defaultHeaders,
            },
          }),
    }).then(async (response) => {
      if (response.status === 200) {
        const json = await response.json();
        if (responseType === 'full') {
          return json;
        }
        if (json.result === 'success') {
          return json.data;
        } else {
          throw json;
        }
      } else {
        throw response;
      }
    });
  }
}

export default function ApiServiceProvider({
  id,
  apiConfig,
  endpoints,
  tokenTTL,
  tokenHeader,
  children,
}) {
  return (
    <ServiceProvider
      serviceId={id}
      serviceClass={ApiService}
      dependencies={{ apiConfig, endpoints, tokenTTL, tokenHeader }}
    >
      {children}
    </ServiceProvider>
  );
}
