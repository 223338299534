import React from 'react';

import Service from '../core/Service';
import ServiceProvider from '../core/ServiceProvider';

export class AssetService extends Service {
  // dependencies
  base = null;

  makeImagePath(filename) {
    return `/${this.base}/images/${filename}`;
  }
}

export default function AssetServiceProvider({ id, base, children }) {
  return (
    <ServiceProvider serviceId={id} serviceClass={AssetService} dependencies={{ base }}>
      {children}
    </ServiceProvider>
  );
}
