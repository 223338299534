import React from 'react';
import clsx from 'clsx';

import { injectedComponent, Text } from '@base/core';

export default injectedComponent(
  {
    name: 'PostRegistrationDialog',
    services: ['dialog'],
    styles: ['dialog', 'targetDialog'],
  },
  function PostRegistrationDialog({
    services: { dialog },
    styles: { dialog: dialogStyles, targetDialog: targetDialogStyles },
  }) {
    const email = dialog.param ? atob(dialog.param) : '';
    return (
      <div className={clsx(targetDialogStyles.dialog)}>
        <h3 className={dialogStyles.dialogTitle}>
          <Text>dialog.post_signup.title</Text>
        </h3>
        <p className={dialogStyles.description}>
          <Text>dialog.post_signup.text__description</Text>
          <div className={targetDialogStyles.registeredEmail}>{email}</div>
        </p>
        <p className={dialogStyles.description}>
          <Text>dialog.post_signup.text__footer</Text>
        </p>
      </div>
    );
  }
);
