import React from 'react';
import clsx from 'clsx';

import { Text, injectedComponent } from '@base/core';

export default injectedComponent(
  {
    name: 'WebHeaderUserInfo',
    services: ['user'],
    components: ['UserAvatar'],
    styles: ['text', 'self'],
  },
  function WebHeaderUserInfo({
    hasMenu,
    isUserMenuOpened,
    setUserMenuState,
    openDialog,
    logout,
    services: { user },
    components: { UserAvatar },
    styles: { text: textStyles, self: selfStyles },
  }) {
    const handleMenuClick = () => setUserMenuState(!isUserMenuOpened);

    const handleMySurveys = () => {
      document.location.assign('/my/');
    };

    return (
      <div className={selfStyles.userInfo} onClick={handleMenuClick}>
        <span className={clsx(textStyles.appBarMenuText, selfStyles.userNameText)}>
          {user.shortName}
        </span>
        {hasMenu && (
          <button
            className={clsx(selfStyles.dropdownBtn, {
              [selfStyles.openedDropdownBtn]: isUserMenuOpened,
            })}
          ></button>
        )}
        <UserAvatar src={user.avatar} initials={user.initials} />
        {hasMenu && isUserMenuOpened && (
          <div className={clsx(textStyles.dropdownText, selfStyles.dropdownMenu)}>
            <div className={selfStyles.menuItem} onClick={handleMySurveys}>
              <span>
                <Text>navigation.my_surveys</Text>
              </span>{' '}
              <span className={selfStyles.menuItemArrow}></span>
            </div>
            <div className={selfStyles.menuItem} onClick={openDialog('profile', { byId: true })}>
              <span>
                <Text>navigation.my_profile</Text>
              </span>{' '}
              <span className={selfStyles.menuItemArrow}></span>
            </div>
            <div
              className={selfStyles.menuItem}
              onClick={openDialog('transactions', { byId: true })}
            >
              <span>
                <Text>navigation.transactions</Text>
              </span>
              <span className={selfStyles.menuItemArrow}></span>
            </div>
            <div
              className={selfStyles.menuItem}
              onClick={openDialog('profile', { byId: true, scrollDown: true })}
            >
              <span>
                <Text>navigation.settings</Text>
              </span>
              <span className={selfStyles.menuItemArrow}></span>
            </div>
            <div className={selfStyles.menuItem} onClick={logout}>
              <span>
                <Text>navigation.logout</Text>
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
);
