import React, { useRef } from 'react';

import Service from '../core/Service';
import ServiceProvider from '../core/ServiceProvider';

import { injectedService } from '@base/core';

const BEFORE_CLOSE_TIMEOUT = 5000;
const BEFORE_REMOVE_TIMEOUT = 600;

export class NotifierService extends Service {
  // dependencies
  api = null; // ApiService
  // refs
  stackRef = null;
  // properties
  stack = [];
  topPosition = false;

  makeId() {
    return +Date.now();
  }

  push(content, options = {}) {
    const id = options.id || this.makeId();
    const newPopUp = {
      id,
      content,
      fade: false,
      handler: options.handler,
      hideButton: options.hideButton,
      style: options.style,
      topPosition: !!options.topPosition,
    };
    const newStack = [...this.stack, newPopUp];
    this.stackRef.current = newStack;
    this.updateState({ stack: newStack, topPosition: newPopUp.topPosition });
    if (!options.timeless) {
      setTimeout(() => this.close(id), BEFORE_CLOSE_TIMEOUT);
    }
  }

  close(id) {
    const popup = this.stackRef.current.find((popup) => popup.id === id);
    if (!popup) return;
    popup.fade = true;
    const newStack = [...this.stackRef.current];
    this.stackRef.current = newStack;
    this.updateState(
      { stack: newStack, ...(!newStack.length ? { topPosition: false } : {}) },
      { forceUpdate: true }
    );
    setTimeout(() => this.remove(id), BEFORE_REMOVE_TIMEOUT);
  }

  closeAll() {
    this.updateState({ stack: [], topPosition: false });
  }

  remove(id) {
    const newStack = this.stackRef.current.filter((popup) => popup.id !== id);
    this.stackRef.current = newStack;
    this.updateState({ stack: newStack, ...(!newStack.length ? { topPosition: false } : {}) });
  }
}

export default injectedService(
  {
    name: 'NotifierServiceProvider',
    services: ['api'],
    components: ['PopUpContainer'],
  },
  function NotifierServiceProvider({
    id,
    children,
    rootElement,
    services: { api },
    components: { PopUpContainer },
  }) {
    const stackRef = useRef();
    return (
      <ServiceProvider
        serviceId={id}
        serviceClass={NotifierService}
        dependencies={{ api }}
        refs={{ stackRef }}
      >
        {children}
        <PopUpContainer serviceId={id} rootElement={rootElement} />
      </ServiceProvider>
    );
  }
);
