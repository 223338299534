import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { injectedComponent } from '@base/core';

export default injectedComponent(
  {
    name: 'CountrySelector',
    services: ['settings'],
    components: ['StyledSelect'],
  },
  function CountrySelector({
    services: {
      settings: { countries },
    },
    components: { StyledSelect },
  }) {
    const selectorRef = useRef();

    const [countryId, setCountryId] = useState(0);

    const currentCountry = countries.find(({ value }) => value === +countryId);

    const handleCountryChange = (value) => {
      setCountryId(+value);
    };

    useEffect(() => {
      if (!selectorRef.current) {
        selectorRef.current = document.querySelector('.js-countrySelector');
        setCountryId(121);
      }
    }, []);

    return selectorRef.current
      ? ReactDOM.createPortal(
          <StyledSelect
            options={countries}
            currentOption={currentCountry}
            onValueChange={handleCountryChange}
            imageBaseUrl="countries/"
            drawIcon={true}
            fullWidth={false}
            scrolled={false}
          />,
          selectorRef.current
        )
      : null;
  }
);
