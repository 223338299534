import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

export default function Spinner({ animationData, speed }) {
  const containerRef = useRef();
  const animation = useRef(null);

  useEffect(() => {
    if (animation.current === null) {
      animation.current = lottie.loadAnimation({
        container: containerRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      });
      animation.current.setSpeed(speed || 1);
      animation.current.play();
    }
    return () => {
      if (animation.current !== null) {
        animation.current.destroy();
      }
    };
  }, []);

  return <div ref={containerRef}></div>;
}
