import React, { useState, useContext } from 'react';

export const ServiceRegistryContext = React.createContext({});

const serviceRegistry = {};

class ServiceRegistryAPI {
  getServiceContext = (serviceId) => serviceRegistry[serviceId] || null;

  registerServiceContext(serviceId, context) {
    if (serviceRegistry[serviceId]) {
      throw new Error(`Service with id '${serviceId}' is already registered`);
    }
    serviceRegistry[serviceId] = context;
  }
}

function makeServiceRegistryAPI() {
  return new ServiceRegistryAPI();
}

export function ServiceRegistry({ children }) {
  const [registryAPI] = useState(makeServiceRegistryAPI);
  return (
    <ServiceRegistryContext.Provider value={registryAPI}>
      {children}
    </ServiceRegistryContext.Provider>
  );
}

export default function useService(serviceId, preferredService) {
  const serviceContext = serviceRegistry[serviceId];
  if (!serviceContext && !preferredService) {
    throw new Error(`Attempt to use an unregistered service '${serviceId}'`);
  }
  const service = useContext(serviceContext);
  return preferredService || service;
}
