import React from 'react';

import Service from '../core/Service';
import ServiceProvider from '../core/ServiceProvider';

export class SettingsService extends Service {
  routes = null;
  i18n = null;
  countries = null;
  animation = null;
  survey = null;
  keys = null;
  project = null;
}

export default function SettingsServiceProvider({ id, settings, children }) {
  return (
    <ServiceProvider serviceId={id} serviceClass={SettingsService} dependencies={{ ...settings }}>
      {children}
    </ServiceProvider>
  );
}
