import React, { useRef } from 'react';

import Service from '../core/Service';
import ServiceProvider from '../core/ServiceProvider';

import { injectedService } from '@base/core';

const DIALOG_URL_ID_KEY = 'dialog';
const DIALOG_URL_PARAM_KEY = 'dialog-value';
const DEFERRED_DIALOG_KEY = 'deferredDialog';

function updateSearchParams(dialogId, param) {
  const searchParams = new URLSearchParams(window.location.search);
  if (dialogId) {
    if (searchParams.has(DIALOG_URL_ID_KEY)) {
      searchParams.set(DIALOG_URL_ID_KEY, dialogId);
    } else {
      searchParams.append(DIALOG_URL_ID_KEY, dialogId);
    }
    if (param) {
      if (searchParams.has(DIALOG_URL_PARAM_KEY)) {
        searchParams.set(DIALOG_URL_PARAM_KEY, param);
      } else {
        searchParams.append(DIALOG_URL_PARAM_KEY, param);
      }
    }
  } else {
    searchParams.delete(DIALOG_URL_ID_KEY);
    searchParams.delete(DIALOG_URL_PARAM_KEY);
  }
  const searchString = searchParams.toString();
  history.pushState({}, null, location.pathname + (searchString ? '?' + searchString : ''));
}

export class DialogService extends Service {
  // dependencies
  api = null;
  dialogSettings = null;
  // refs
  dialogMapRef = null;
  // properties
  current = null;
  previous = null;
  param = null;

  initState(dependencies, refs) {
    super.initState(dependencies, refs);
    const searchParams = new URLSearchParams(window.location.search);
    const dialogId = searchParams.get(DIALOG_URL_ID_KEY);
    const dialogParam = searchParams.get(DIALOG_URL_PARAM_KEY);
    if (dialogId) {
      if (!this.dialogSettings[dialogId]) {
        console.error(`Attempt to open unexisted dialog '${dialogId}'`);
        this.close();
        return;
      }
      this.current = dialogId;
      this.param = dialogParam;
    } else {
      const deferred = sessionStorage.getItem(DEFERRED_DIALOG_KEY);
      if (deferred) {
        setTimeout(() => {
          this.open(deferred);
        });
      }
    }
  }

  open(dialogComponent, param) {
    const dialogComponentName = dialogComponent.name;
    if (!this.dialogMapRef.current) {
      this._prepareDialogMapRef();
    }
    const dialogId = this.dialogMapRef.current[dialogComponentName];
    this.openById(dialogId, param);
  }

  close() {
    sessionStorage.removeItem(DEFERRED_DIALOG_KEY);
    updateSearchParams();
    const html = document.querySelector('html');
    html.style.overflow = 'auto';
    this.updateState({ previous: this.current, current: null, param: null });
  }

  defer(dialogId) {
    sessionStorage.setItem(DEFERRED_DIALOG_KEY, dialogId);
  }

  goBack() {
    this.openById(this.previous);
  }

  openById(dialogId, param) {
    if (!dialogId) {
      console.error(`Attempt to open unexisted dialog '${dialogId}'`);
      return;
    }
    sessionStorage.removeItem(DEFERRED_DIALOG_KEY);
    updateSearchParams(dialogId, param);
    const html = document.querySelector('html');
    html.style.overflow = 'hidden';
    this.updateState({
      previous: this.current,
      current: dialogId,
      param,
    });
  }

  _prepareDialogMapRef() {
    this.dialogMapRef.current = Object.entries(this.dialogSettings).reduce(
      (container, [dialogId, dialogInfo]) => {
        container[dialogInfo.dialog] = dialogId;
        return container;
      },
      {}
    );
  }
}

export default injectedService(
  {
    name: 'DialogServiceProvider',
    services: ['api'],
    components: ['DialogPortal'],
  },
  function DialogServiceProvider({
    id,
    children,
    dialogRootElement,
    dialogSettings,
    services: { api },
    components: { DialogPortal },
  }) {
    const dialogMapRef = useRef();
    return (
      <ServiceProvider
        serviceId={id}
        serviceClass={DialogService}
        dependencies={{ api, dialogSettings }}
        refs={{ dialogMapRef }}
      >
        {children}
        <DialogPortal dialogRootElement={dialogRootElement} />
      </ServiceProvider>
    );
  }
);
