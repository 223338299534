import React from 'react';
import _isMatch from 'underscore/modules/isMatch';

import Service from '../core/Service';
import ServiceProvider from '../core/ServiceProvider';
import { injectedService } from '@base/core';

const TOKEN_KEY = 'authToken';
const TOKEN_TS_KEY = 'authTokenTS';
const TOKEN_TTL = 6 * 60 * 60 * 1000; // ms, 6 hour;

const processCollision = async (access, urlToken, storedToken) => {
  const urlTokenAccess = await access.api.isLoggedIn({ options: { authToken: urlToken } });
  const storedTokenAccess = await access.api.isLoggedIn({ options: { authToken: storedToken } });
  if (urlTokenAccess.memberId === storedTokenAccess.memberId) {
    if (urlTokenAccess.fullMemberAccess) {
      access.login(urlToken, urlTokenAccess);
    } else {
      access.login(storedToken, storedTokenAccess);
    }
  } else {
    access.login(urlToken, urlTokenAccess);
  }
};

export class AccessService extends Service {
  // dependencies
  api = null; // ApiService
  onAccessChange = null;

  // properties
  tokenDetected = false;
  detected = false;
  memberId = null;
  isLogged = false;
  fullMemberAccess = false;
  viewCabinetMemberAccess = false;

  initState(dependencies, refs) {
    super.initState(dependencies, refs);
    const searchParams = new URLSearchParams(window.location.search);
    const urlToken = searchParams.get('token');
    const storedToken = localStorage.getItem(TOKEN_KEY);
    if (urlToken && storedToken && urlToken !== storedToken) {
      this.tokenDetected = true;
      processCollision(this, urlToken, storedToken);
    } else {
      const tsNow = Date.now();
      if (urlToken) {
        localStorage.setItem(TOKEN_KEY, urlToken);
        localStorage.setItem(TOKEN_TS_KEY, tsNow);
        this.tokenDetected = true;
        this._setToken(urlToken);
      } else if (storedToken) {
        const storedTokenTS = localStorage.getItem(TOKEN_TS_KEY);
        if (storedTokenTS && tsNow - storedTokenTS < TOKEN_TTL) {
          this.tokenDetected = true;
          this._setToken(storedToken);
        } else {
          setTimeout(() => this.logout());
        }
      }
    }
  }

  prepareState(dependencies, prevService, newState) {
    super.prepareState(dependencies, prevService, newState);
    if (prevService.api !== dependencies.api) {
      this.requestAccess();
    }
  }

  requestAccess() {
    const request = async () => {
      try {
        const data = await this.api.isLoggedIn();
        if (data) {
          this.updateState({ detected: true, ...data });
          if (typeof this.onAccessChange === 'function') {
            this.onAccessChange(data);
          }
        }
      } catch (e) {
        this.updateState({
          tokenDetected: false,
          detected: true,
          memberId: null,
          isLogged: false,
          fullMemberAccess: false,
          viewCabinetMemberAccess: false,
        });
      }
    };
    this.api && request();
  }

  matchState(dependencies, newState) {
    const match = super.matchState(dependencies, newState);
    return match;
  }

  hasAnyAccess() {
    return this.isLogged && (this.fullMemberAccess || this.viewCabinetMemberAccess);
  }

  permit(access = {}) {
    return _isMatch(this, access);
  }

  login(token, accessState) {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(TOKEN_TS_KEY, Date.now());
    this._setToken(token);
    this.updateState({ detected: true, ...accessState });
  }

  logout() {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(TOKEN_TS_KEY);
    this.updateState({
      tokenDetected: false,
      detected: false,
      memberId: null,
      isLogged: false,
      fullMemberAccess: false,
      viewCabinetMemberAccess: false,
    });
    this._setToken(null);
  }

  _setToken(token) {
    this.api.updateState({ token });
  }
}

export default injectedService(
  {
    name: 'AccessServiceProvider',
    services: ['api'],
  },
  function AccessServiceProvider({ id, children, services: { api }, onAccessChange }) {
    return (
      <ServiceProvider
        serviceId={id}
        serviceClass={AccessService}
        dependencies={{ api, onAccessChange }}
      >
        {children}
      </ServiceProvider>
    );
  }
);
