import React from 'react';
import ReactDOM from 'react-dom';

import { injectedComponent } from '@base/core';

export default injectedComponent(
  {
    name: 'DialogPortal',
    services: ['access', 'dialog'],
    components: ['ModalDialog', '@dialog:dialogs'],
  },
  function DialogPortal({
    dialogRootElement,
    services: { access, dialog },
    components: { ModalDialog, dialogs },
  }) {
    if (dialog.current === null) {
      return ReactDOM.createPortal(null, dialogRootElement);
    }
    const dialogInfo = dialog.dialogSettings[dialog.current];
    let Dialog;
    if (access.permit(dialogInfo.access)) {
      Dialog = dialogs[dialogInfo.dialog];
      if (!Dialog) {
        throw new Error(`Attempt to open unexisted dialog '${dialog.current}'`);
      }
    } else {
      if (!dialog.dialogSettings.login || !dialog.dialogSettings.login.dialog) {
        throw new Error(`Application must contain predefined dialog 'login', but it is missed`);
      }
      Dialog = dialogs[dialog.dialogSettings.login.dialog];
      dialog.defer(dialog.current);
    }

    return ReactDOM.createPortal(
      <ModalDialog onClose={() => dialog.close()}>
        <Dialog />
      </ModalDialog>,
      dialogRootElement
    );
  }
);
