const i18n = {
  'Member authentication error: "INVALID-LOGIN-PASSWORD"': 'Неправильный логин или пароль',
  'Member authentication error: "INACTIVE-MEMBER"': 'Пользователь не активен',
  'This member does not exist in panel.': 'Пользователь с таким email не зарегистрирован',
  'This value should not be blank.': 'Поле не может быть пустым',
  'Member already exist in panel': 'Такой пользователь уже зарегистрирован',
  'This value is not a valid email address.': 'Некорректный email',
  'This value is too short. It should have 1 character or more.':
    'Значение слишком короткое. Должен быть один или более символов.',
  'Multiple members found by unique data-points': 'Такой email уже зарегистрирован',

  'global_error.multipleMembers': 'Пользователь с таким email уже существует',
  'global_error.unknown_error': 'Что-то пошло не так. Попробуйте позже',
};

export default i18n;
