import React from 'react';

import useService from '../core/ServiceRegistry';

export function useURLBuilder() {
  const language = useService('language');
  const { current, disableUrlPatching } = language;
  return (route, params, search = {}) => {
    const searchString = Object.entries(search)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const preparedSearchString = searchString ? `?${searchString}` : '';
    const routePath = typeof route === 'object' ? route.path : route;
    const routePathWithSearch = `${routePath !== '/' ? routePath : ''}${preparedSearchString}`;
    console.log(disableUrlPatching, routePathWithSearch);
    return disableUrlPatching ? `/${routePathWithSearch}` : `/${current}/${routePathWithSearch}`;
  };
}
