const i18n = {
  'site.title': 'МойОпрос',

  'navigation.faq__desktop': 'Часто задаваемые вопросы',
  'navigation.faq__mobile': 'Часто задаваемые вопросы',
  'navigation.login': 'Вход',
  'navigation.sign_up': 'Регистрация',
  'navigation.my_surveys': 'Мои опросы',
  'navigation.my_profile': 'Мой профиль',
  'navigation.transactions': 'Баланс',
  'navigation.settings': 'Настройки',
  'navigation.logout': 'Выйти',

  'dialog.signup.title': 'Регистрация',
  'dialog.signup.button__create_account': 'Зарегистрироваться',
  'dialog.signup.text__by_clicking_I_agree': 'Я согласен с %policy1%, %policy2% и даю %policy3%',
  'dialog.signup.link__privacy_policy': 'Политикой конфиденциальности',
  'dialog.signup.link__personal_data': 'Согласие на обработку моих персональных данных',
  'dialog.signup.link__terms_and_conditions': 'Условиями использования',
  'dialog.signup.text__have_an_account_q': 'Уже зарегистрированы?',
  'dialog.signup.link__log_in': 'Вход',
  'dialog.signup.error_message__member_already_exist': 'Пользователь с таким email уже существует',
  'dialog.signup.error_message__unknown_error': 'Что-то пошло не так. Попробуйте позже',

  'dialog.post_signup.title': 'Спасибо за регистрацию в МойОпрос',
  'dialog.post_signup.text__description': 'Мы отправили письмо на указанный Вами адрес ',
  'dialog.post_signup.text__footer':
    'Пожалуйста, перейдите по ссылке в письме для активации аккаунта',

  'dialog.password_recovery.title': 'Восстановление пароля',
  'dialog.password_recovery.description':
    'Введите свой email, и мы отправим вам ссылку на смену пароля',
  'dialog.password_recovery.placeholder__your_email': 'Ваш email',
  'dialog.password_recovery.button__send_a_link': 'Отправить ссылку',
  'dialog.password_recovery.text__remembered_q': 'Вспомнили?',
  'dialog.password_recovery.link__back_to_login': 'Назад ко входу',
  'dialog.password_recovery.notification__success':
    'Инструкция по восстановлению пароля отправлена на указанную почту',
  'dialog.password_recovery.error_message__unknown_error':
    'Что-то пошло не так. Попробуйте восстановить пароль позже',

  'dialog.inactive_account.text__message': 'Аккаунт неактивен',

  'dialog.stop_reminder.description': 'Вы успешно отменили подписку на приглашения к этому опросу',

  'dialog.unsubscribe.description':
    'Пожалуйста, подтвердите, что вы хотите отказаться от участия в проекте МойОпрос и удалить свою учетную запись. Обратите внимание, что все ваши баллы будут аннулированы.',
  'dialog.unsubscribe.button__delete': 'Да, удалите мою учетную запись',

  'common.notification__we_use_cookies':
    'We use cookies. By clicking "Ok" button you accept our %value% <a>Cookies Policy</a>. Please use "Opt-out option" column of Cookies Policy to customize settings',
};

export default i18n;
