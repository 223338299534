import React from 'react';
import clsx from 'clsx';

import { injectedComponent, Text } from '@base/core';

export default injectedComponent(
  {
    name: 'UnsubscribeDialog',
    services: ['dialog'],
    styles: ['dialog', 'targetDialog', 'button'],
  },
  function UnsubscribeDialog({
    services: { dialog },
    styles: { dialog: dialogStyles, targetDialog: targetDialogStyles, button: buttonStyles },
  }) {
    const handleOK = () => {
      dialog.close();
    };
    return (
      <div className={clsx(targetDialogStyles.dialog)}>
        <p className={dialogStyles.description}>
          <Text>dialog.unsubscribe.description</Text>
        </p>
        <form className={dialogStyles.form}>
          <button
            type="button"
            className={clsx(buttonStyles.primaryBtn, buttonStyles.primaryBtn__medium)}
            onClick={handleOK}
          >
            <Text>dialog.unsubscribe.button__delete</Text>
          </button>
        </form>
      </div>
    );
  }
);
