import _isEqual from 'underscore/modules/isEqual';

export const $dispatch = Symbol('dispatch');
export const $updateOptions = Symbol('updateOptions');

function copyProps(from, to, mustBePredefined) {
  let copier = (propName) => {
    to[propName] = from[propName];
  };
  if (process.env.NODE_ENV === 'development') {
    copier = (propName) => {
      if (mustBePredefined && typeof to[propName] === 'undefined') {
        throw new Error(
          `Service initialization failed: property '${propName}' must not be undefined`
        );
      }
      to[propName] = from[propName];
    };
  }
  Object.keys(from).forEach(copier);
}

export default class Service {
  onceListeners = [];

  /**
   * Initializes an instance of Service on the first time construction.
   *
   * @param {*} dependencies
   * @memberof Service
   */
  initState(dependencies, refs) {
    if (typeof dependencies === 'object') {
      copyProps(dependencies, this, true);
    }
    if (typeof refs === 'object') {
      copyProps(refs, this, true);
    }
  }

  /**
   * Prepares an instance of Service.
   *
   * @param {?object} dependencies  dependencies of Service
   * @param {?Service} prevService  previous Service state
   * @param {?object} newState      updated part of new Service state
   * @memberof Service
   */
  prepareState(dependencies, prevService, newState) {
    if (typeof prevService === 'object') {
      copyProps(prevService, this);
    }
    if (typeof dependencies === 'object') {
      copyProps(dependencies, this);
    }
    if (typeof newState === 'object') {
      copyProps(newState, this);
    }
    // if (Array.isArray(prevService.onceListeners)) {

    this.onceListeners = this.onceListeners.reduce((newListeners, action) => {
      const { matcher, reaction } = action;
      if (matcher(this)) {
        reaction(this);
      } else {
        newListeners.push(action);
      }
      return newListeners;
    }, []);
    // prevService.onceListeners = null;
    // }
  }

  /**
   * Matches current service state and new state.
   *
   * @param {?object} newState
   * @return {Boolean} true if current state and newState are identical.
   * @memberof Service
   */
  matchState(dependencies, newState = {}) {
    // if (!_isMatch(this, newState)) {
    //   console.log('matchState is false');
    //   return false;
    // }
    // if (!_isMatch(this, dependencies)) {
    //   console.log('matchDependencies is false');
    //   return false;
    // }
    const matchState = Object.keys(newState).every((prop) => _isEqual(newState[prop], this[prop]));
    if (!matchState) {
      // console.log('matchState is false');
      return false;
    }
    if (typeof dependencies === 'object') {
      const matchDependencies = Object.keys(dependencies).every((prop) =>
        Object.is(dependencies[prop], this[prop])
      );
      // console.log('matchDependencies is', matchDependencies);
      return matchDependencies;
    }
    return true;
  }

  addOnceListener(action) {
    // if (!this.onceListeners) {
    //   this.onceListeners = [];
    // }
    this.updateState({ ...this, onceListeners: [...this.onceListeners, action] });
    // this.onceListeners.push(action);
  }

  /**
   * Runs new Service state creation and rerender reaction if symbol $dispatch exists.
   *
   * @memberof Service
   */
  updateState(newState, options) {
    const preparedNewState = options ? { ...newState, [$updateOptions]: options } : newState;
    if (this[$dispatch]) {
      this[$dispatch](preparedNewState);
    }
  }
}
