import React, { useEffect } from 'react';
import { injectedComponent, useText } from '@base/core';

const ERROR_PARAM = 'error';

export default injectedComponent(
  {
    name: 'ErrorParamDetector',
    services: ['dialog', 'notifier'],
  },
  function ErrorParamDetector({
    errorParamName = ERROR_PARAM,
    errorCases = {},
    defaultCase = null,
    services: { dialog, notifier },
  }) {
    const text = useText();
    const searchParams = new URLSearchParams(window.location.search);
    const error = searchParams.get(errorParamName);
    useEffect(() => {
      if (!error) return;
      const errorCase = errorCases[error];
      if (errorCase) {
        if (typeof errorCase === 'function') {
          errorCase({ dialog, notifier });
        } else if (typeof errorCase === 'object' && errorCase.type === 'notification') {
          const message = text(errorCase.textKey);
          if (message) notifier.push(message, errorCase.notifierOptions || {});
        }
      } else if (defaultCase) {
        if (typeof defaultCase === 'function') {
          defaultCase({ dialog, notifier });
        } else if (typeof defaultCase === 'object' && defaultCase.type === 'notification') {
          const message = text(defaultCase.textKey);
          if (message) notifier.push(message, defaultCase.notifierOptions || {});
        }
      }
      searchParams.delete(errorParamName);
      const searchString = searchParams.toString();
      history.replaceState({}, null, location.pathname + (searchString ? '?' + searchString : ''));
    }, []);
    return null;
  }
);
