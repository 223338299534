module.exports = {
  auth: {
    method: 'POST',
    path: '/member/panel/auth',
    options: {
      noAuthToken: true,
    },
    // mock: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJtZW1iZXIiOjE5Mjc1LCJwYW5lbCI6Im1tIiwiZnVsbE1lbWJlckFjY2VzcyI6dHJ1ZSwidmlld0NhYmluZXRNZW1iZXJBY2Nlc3MiOmZhbHNlLCJleHAiOjE2NTc2NzAzNTF9.cQbslf5NnXF9HMxQgAeAftgUs3ECxe158tnNHrBp_8lrTbVJUZ0jeP9RelIj-WLcM4nE83pCt5aRivUH_g-taMgUfVcLW4n0YH-IEWbPqjwE7Mo_xZjjww627wmMQ5_Zc5Grc3V5K_GYzazZNGRRqmCRG5YTfyoe0wDlvxaZV4Q',
  },
  isLoggedIn: {
    method: 'POST',
    path: '/member/is-logged-in',
    // mock: (counter) => {
    //   return {
    //     isLogged: true,
    //     fullMemberAccess: counter > 2,
    //     viewCabinetMemberAccess: true,
    //   };
    // },
  },
  getProfile: {
    method: 'POST',
    path: '/member/profile',
    // mock: {
    //   id: 'e53894798db3252e1dc4fbba647f8d6e',
    //   email: 'user19275@example.com',
    //   country: null,
    //   firstname: 'John',
    //   lastname: 'Connor',
    //   zipcode: '354068',
    // },
  },
  register: {
    method: 'POST',
    path: '/member/register',
    options: {
      noAuthToken: true,
    },
  },
  resorePassword: {
    method: 'POST',
    path: '/member/panel/restore-password',
  },
  sendMessage: {
    method: 'POST',
    path: '/member/send-support-message',
  },
};
