import React, { createContext, useContext, useReducer, useEffect, useMemo } from 'react';

import { $dispatch, $updateOptions } from './Service';
import { ServiceRegistryContext } from './ServiceRegistry';

function makeServiceInitialState({ ServiceClass, dependencies, refs }) {
  const serviceState = new ServiceClass();
  serviceState.initState(dependencies, refs);
  if (process.env.NODE_ENV === 'development') {
    console.groupCollapsed(`Service '${ServiceClass.name}' has been initialized`);
    console.log('dependencies:', dependencies);
    console.log('state:', serviceState);
    console.groupEnd();
  }
  return serviceState;
}

const serviceReducer = (dependencies) => (service, newState) => {
  if (
    (newState && newState[$updateOptions]?.forceUpdate === true) ||
    !service.matchState(dependencies, newState)
  ) {
    const ServiceClass = Object.getPrototypeOf(service).constructor;
    const newService = new ServiceClass();
    newService.prepareState(dependencies, service, newState);
    if (process.env.NODE_ENV === 'development') {
      console.groupCollapsed(`Service '${ServiceClass.name}' has been updated`);
      console.log('dependencies:', dependencies);
      console.log('new values:', newState);
      console.log('new state:', newService);
      console.groupEnd();
    }
    return newService;
  }
  return service;
};

export default function ServiceProvider({
  serviceId,
  serviceClass,
  context,
  dependencies = {},
  refs,
  children,
}) {
  const serviceRegisterAPI = useContext(ServiceRegistryContext);

  const [service, dispatch] = useReducer(
    serviceReducer(dependencies),
    { ServiceClass: serviceClass, dependencies, refs },
    makeServiceInitialState
  );

  if (!($dispatch in service)) {
    service[$dispatch] = dispatch;
  }
  if (!Object.isFrozen(service)) {
    Object.freeze(service);
  }

  const serviceContext = useMemo(() => {
    return context || createContext();
  }, []);

  useMemo(() => {
    if (serviceRegisterAPI && serviceId) {
      serviceRegisterAPI.registerServiceContext(serviceId, serviceContext);
    }
  }, []);

  useEffect(() => {
    dispatch();
  }, Object.values(dependencies));

  const ServiceContext = serviceContext;

  return <ServiceContext.Provider value={service}>{children}</ServiceContext.Provider>;
}
