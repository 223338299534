export default function initPixel() {
  const headElement = document.getElementsByTagName('head')[0];
  var script = document.createElement('script');
  script.src =
    'https://my.rtmark.net/p.js?f=sync&lr=1&partner=ac679c0c0b16fdb19a06a0f69959a03762b1d6191a1c518d06ac103a73a7246f';
  script.async = false;
  headElement.appendChild(script);
  headElement.insertAdjacentHTML(
    'beforeend',
    '<noscript><img src="https://my.rtmark.net/img.gif?f=sync&lr=1&partner=ac679c0c0b16fdb19a06a0f69959a03762b1d6191a1c518d06ac103a73a7246f" width="1" height="1" /></noscript>'
  );
}
