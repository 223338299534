import React, { useState } from 'react';
import clsx from 'clsx';

import { useLocaleSwitchApp, useResponseErrorMessage } from '@base/hooks/i18n';
import { injectedComponent, Text, useText } from '@base/core';
import { UTM_SOURCE_KEY, PARTNER_ID_KEY, AUTH_NETWORKS } from '../../shared/configs/constants';

export default injectedComponent(
  {
    name: 'LoginDialog',
    services: ['api', 'access', 'dialog'],
    components: ['AuthDialogBase'],
    styles: ['dialog', 'button', 'self'],
  },
  function LoginDialog({
    services: { api, access, dialog },
    components: { AuthDialogBase },
    styles: { dialog: dialogStyles, self: selfStyles },
    diSettings,
  }) {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [updating, setUpdating] = useState(false);
    const [authError, setAuthError] = useState(null);
    const [validity, setValidity] = useState(false);
    const text = useText();
    const switchApp = useLocaleSwitchApp();
    const getErrorMessage = useResponseErrorMessage();
    const utm_source = sessionStorage.getItem(UTM_SOURCE_KEY) || '';
    const partnerId = sessionStorage.getItem(PARTNER_ID_KEY) || '';

    const handleLoginChange = (value) => {
      if (updating) return;
      setAuthError(null);
      setLogin(value);
    };

    const handlePasswordChange = (value) => {
      if (updating) return;
      setAuthError(null);
      setPassword(value);
    };

    const handleSignUpDialog = () => {
      if (diSettings.switchAppOnSignUp) {
        dialog.close();
        return switchApp(diSettings.switchAppOnSignUp, { dialog: 'signup' });
      }
      dialog.openById('signup');
    };

    const handlePasswordRecoveryDialog = () => {
      if (diSettings.switchAppOnRecovery) {
        dialog.close();
        return switchApp(diSettings.switchAppOnRecovery, { dialog: 'password-recovery' });
      }
      dialog.openById('password-recovery', btoa(login));
    };

    const handleSNLogin = (sn, baseUrl) => {
      if (!AUTH_NETWORKS.includes(sn)) return;
      const searchParams = new URLSearchParams();
      if (utm_source) {
        searchParams.append('utm_source', utm_source);
      }
      if (partnerId) {
        searchParams.append('partner', partnerId);
      }
      const params = searchParams.size > 0 ? `?${searchParams.toString()}` : '';
      document.location.assign(`${baseUrl}/auth/${sn}${params}`);
    };

    const processResponseError = (response) => {
      if (response && response.result === 'error') {
        const message = getErrorMessage(response);
        if (message.match(/^Member \d+ is not exist in panel \d+\.$/)) {
          return setAuthError(text('dialog.login.error_message__invalid_login_password'));
        }
        setAuthError(message);
      }
    };

    // const processResponseError = (response) => {
    //   if (response && response.result === 'error') {
    //     if (response.message.includes('INVALID-LOGIN-PASSWORD')) {
    //       return setAuthError(text('dialog.login.error_message__invalid_login_password'));
    //     }
    //     if (response.message.includes('INACTIVE-MEMBER')) {
    //       return setAuthError(text('dialog.login.error_message__inactive_member'));
    //     }
    //     setAuthError(text('dialog.login.error_message__unknown_error'));
    //   }
    // };

    const handleLogin = async () => {
      if (updating || !validity) return;
      setUpdating(true);
      try {
        const response = await api.auth({
          payload: { identity: 'email', login, password },
          responseType: 'full',
        });
        if (response && response.result === 'success') {
          access.login(response.data);
          if (diSettings.switchAppOnLogin) {
            dialog.close();
            if (typeof diSettings.onLogin === 'function') {
              diSettings.onLogin();
            }
            switchApp(diSettings.switchAppOnLogin);
          }
        } else {
          processResponseError(response);
        }
      } catch (e) {
        if (typeof e.json === 'function') {
          const response = await e.json();
          processResponseError(response);
        } else {
          setAuthError(text('dialog.login.error_message__unknown_error'));
        }
        // if (e.status === 500) {
        //   const response = await e.json();
        //   processResponseError(response);
        // } else {
        //   setAuthError(text('dialog.login.error_message__unknown_error'));
        // }
      } finally {
        setUpdating(false);
      }
    };

    const validateChild = (validationId, childValidity) => {
      setValidity(childValidity);
    };

    return (
      <AuthDialogBase
        login={login}
        onSNLogin={handleSNLogin}
        onLoginChange={handleLoginChange}
        password={password}
        onPasswordChange={handlePasswordChange}
        updating={updating}
        authError={authError}
        validity={validity}
        validate={validateChild}
        title={<Text>dialog.login.title</Text>}
        beforeActionButton={
          <span className={clsx(dialogStyles.regularText, selfStyles.regularText)}>
            <span className={dialogStyles.dialogLink} onClick={handlePasswordRecoveryDialog}>
              <Text>dialog.login.link__forgot_password_q</Text>
            </span>
          </span>
        }
        actionButtonText={<Text>dialog.login.button__login</Text>}
        onAction={handleLogin}
        afterActionButton={
          <div className={dialogStyles.regularText}>
            <Text>dialog.login.text__dont_have_an_account_q</Text>
            <span className={dialogStyles.dialogLink} onClick={handleSignUpDialog}>
              <Text>dialog.login.link__create_account</Text>
            </span>
          </div>
        }
      />
    );
  }
);
