import React from 'react';

import Service from '@base/core/Service';
import ServiceProvider from '@base/core/ServiceProvider';
import useService from '@base/core/ServiceRegistry';

export class UserService extends Service {
  // dependencies
  api = null;
  needBalance = null;
  // properties
  id = null;
  email = null;
  country = null;
  firstname = null;
  lastname = null;
  zipcode = null;
  avatar = null;
  balance = null;

  isProfileFetched = false;
  isBalanceFetched = false;

  initState(dependencies) {
    super.initState(dependencies);
    this.actualize();
  }

  prepareState(dependencies, prevService, newState) {
    super.prepareState(dependencies, prevService, newState);
    if (prevService.api !== dependencies.api) {
      this.actualize();
    }
  }

  actualize() {
    if (!this.api.token) return;
    this.api.getProfile().then((data) => {
      if (data) {
        this.updateState({ isProfileFetched: true, ...data });
      } else {
        this.updateState({ isProfileFetched: true });
      }
    });
    if (this.needBalance) {
      this.api.getWallet().then((data) => {
        this.updateState({ isBalanceFetched: true, balance: data?.balance || 0 });
      });
    }
  }

  get fullName() {
    if (!this.isProfileFetched) return '';
    const fullNameArray = [];
    if (this.firstname) {
      fullNameArray.push(this.firstname);
    }
    if (this.lastname) {
      fullNameArray.push(this.lastname);
    }
    return fullNameArray.length ? fullNameArray.join(' ') : 'New User';
  }

  get shortName() {
    if (!this.isProfileFetched) return '';
    const name = this.firstname || this.lastname || 'New User';
    return name;
  }

  get initials() {
    if (!this.isProfileFetched) return '';
    const fullNameArray = [];
    if (this.firstname) {
      fullNameArray.push(this.firstname[0]);
    }
    if (this.lastname) {
      fullNameArray.push(this.lastname[0]);
    }
    return fullNameArray.length ? fullNameArray.join('') : 'NU';
  }

  get realBalance() {
    return this.isBalanceFetched ? (this.balance >= 0 ? this.balance : 0) : 0;
  }
}

export default function UserServiceProvider({ id, needBalance, children }) {
  const api = useService('api');

  return (
    <ServiceProvider serviceId={id} serviceClass={UserService} dependencies={{ api, needBalance }}>
      {children}
    </ServiceProvider>
  );
}
