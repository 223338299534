import React, { useRef, useEffect } from 'react';
import Swiper, { Navigation, Pagination, Autoplay, Keyboard } from 'swiper';
import { injectedComponent } from '@base/core';

const swiperSettings = (charity) => ({
  modules: [Navigation, Pagination, Autoplay, Keyboard],
  speed: 500,
  slidesPerView: 1,
  spaceBetween: 40,
  centeredSlides: true,
  pagination: {
    el: '.slider__pagination',
    bulletActiveClass: 'slider__bullet-active',

    clickable: true,
    slideToClickedSlide: true,
    bulletClass: 'slider__bullet',
    renderBullet: function (index, className) {
      return `<span class="${className}" style="background-image: url(&quot;/public/images/charity/${charity[index].image}&quot;)" onclick="window.onSwiperClick();">\
          <svg class="progressBarContainer">\
            <circle class="progressBar-circular" cx="30" cy="30" r="27"></circle>\
          </svg>\
        </span>`;
    },
  },
  keyboard: {
    enabled: true,
  },

  autoplay: {
    delay: 10000,
    disableOnInteraction: false,
  },
  loop: true,
});

export default injectedComponent(
  {
    name: 'Charity',
    services: ['settings'],
  },
  function Charity({
    services: {
      settings: {
        project: { charity },
      },
    },
  }) {
    useEffect(() => {
      if (window.charitySwiper) {
        window.charitySwiper.destroy();
      }
      window.charitySwiper = new Swiper('.swiper', swiperSettings(charity));
      window.charitySwiper.on('slideChange', function () {
        window.stopCharityPlayers();
      });
      window.onSwiperClick = () => {
        window.stopCharityPlayers();
      };
      var tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }, []);
  }
);
