import { getBrowserLocales } from '../utils/utils';

export default function bootstrap() {
  if (process.env.NODE_ENV === 'development') {
    console.info('\n=== Anketka Lite build info =======================================');
    console.info(`| - Build mode: ${process.env.NODE_ENV}`);
    console.info(`| - Platform: ${process.env.PLATFORM}`);
    console.info(`| - Release: ${process.env.APP_VERSION}`);
    console.info(`| - Branch: ${process.env.BRANCH}`);
    console.info(`| - Commit hash: ${process.env.COMMITHASH}`);
    console.info(`| - Browser locales: ${getBrowserLocales()}`);
    console.info('===================================================================');
    console.info('\n');
  }

  return new Promise((resolve, reject) => {
    return fetch('/api.config.json', {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((api) => resolve(api))
      .catch(() => reject('No api.config.json file'));
  });
}
