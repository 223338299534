import React from 'react';

import Service from '../core/Service';
import useService from '../core/ServiceRegistry';
import ServiceProvider from '../core/ServiceProvider';

const CURRENT_LANGUAGE_KEY = 'currentLangId';

export class LanguageService extends Service {
  // dependencies
  i18n = null;

  // properties
  current = null;
  disableUrlPatching = false;

  initState(dependencies) {
    super.initState(dependencies);
    this.current = localStorage.getItem(CURRENT_LANGUAGE_KEY) || this.i18n.languages[0];
  }

  trySet(langId) {
    if (this.i18n.languages.includes(langId)) {
      if (langId !== this.current) {
        this.updateState({ current: langId });
      }
      return true;
    }
    return false;
  }
}

export default function LanguageServiceProvider({ id, children, disableUrlPatching }) {
  const { i18n } = useService('settings');

  return (
    <ServiceProvider
      serviceId={id}
      serviceClass={LanguageService}
      dependencies={{ i18n, disableUrlPatching }}
    >
      {children}
    </ServiceProvider>
  );
}
