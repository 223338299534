import React, { useState } from 'react';

import { injectedComponent, Text, useText } from '@base/core';

function Question({ questions, index, selfStyles, handleToggle }) {
  const text = useText();
  const questionKey = `faq.${index}.question`;
  const answerKey = `faq.${index}.answer`;
  return (
    <div className={selfStyles.questionContainer} onClick={handleToggle(index)}>
      <div className={selfStyles.question}>
        <Text>{questionKey}</Text>
      </div>
      {questions[index] && (
        <div
          className={selfStyles.answer}
          dangerouslySetInnerHTML={{ __html: text(answerKey) }}
        ></div>
      )}
    </div>
  );
}

export default injectedComponent(
  {
    name: 'FAQDialog',
    styles: ['self'],
  },
  function FAQDialog({ styles: { self: selfStyles } }) {
    const [questions, setQuestions] = useState({});
    const text = useText();

    const handleToggle = (index) => (e) => {
      if (e.target.nodeName === 'A') return;
      setQuestions({ ...questions, [index]: !questions[index] });
    };

    const faqCount = +text('faq.count') || 0;

    return (
      <div className={selfStyles.dialog}>
        <div className={selfStyles.heading}>
          <Text>faq.title</Text>
        </div>
        <div className={selfStyles.questionList}>
          {[...Array(faqCount)].map((noValue, index) => (
            <Question
              key={index + 1}
              questions={questions}
              index={index + 1}
              selfStyles={selfStyles}
              handleToggle={handleToggle}
            />
          ))}
        </div>
      </div>
    );
  }
);
