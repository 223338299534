import useService from './ServiceRegistry';

export function registerDependencyInjections({ services, hooks, components, styles }) {
  window.$$DIRegistry = { services, hooks, components, styles };
  window.$$DIRegistry.tags = {};
  Object.entries(components).forEach(([compId, compRecord]) => {
    const tags = compRecord.tags;
    if (!tags) return;
    tags.split(' ').forEach((tag) => {
      if (!tag) return;
      if (!window.$$DIRegistry.tags[tag]) {
        window.$$DIRegistry.tags[tag] = {};
      }
      window.$$DIRegistry.tags[tag][compId] = compRecord;
    });
  });
}

const noDISettings = {};

const getSelf = (itemRecord) => (typeof itemRecord === 'function' ? itemRecord : itemRecord.self);

function prepareItemFromRegistry(itemKeys, targetName) {
  return (container, itemId) => {
    if (itemId[0] === '@') {
      const match = itemId.match(/@(\w+)\:(\w+)/);
      if (match.length === 3) {
        const taggedRegistry = window.$$DIRegistry.tags[match[1]];
        const taggedComponents = Object.keys(taggedRegistry).reduce(
          prepareItemFromRegistry(itemKeys, targetName),
          {}
        );
        container[match[2]] = taggedComponents;
      } else {
        throw new Error(
          `Attempt to use an erroneous tag expression '${itemId}' inside component '${targetName}'`
        );
      }
    } else {
      const itemRecord = window.$$DIRegistry[itemKeys][itemId];
      if (!itemRecord) {
        throw new Error(
          `Attempt to use an unregistered '${itemId}' '${itemRecord}' inside component '${targetName}'`
        );
      }
      container[itemId] = getSelf(itemRecord);
    }
    return container;
  };
}

function prepareItems(itemKey, descriptor, targetRecord, targetName) {
  let items;
  const itemKeys = itemKey + 's';
  if (descriptor[itemKeys]) {
    const descriptorItems =
      descriptor[itemKeys].length === 1 && descriptor[itemKeys][0] === Infinity
        ? Object.keys(window.$$DIRegistry[itemKeys])
        : descriptor[itemKeys];
    items = descriptorItems.reduce(prepareItemFromRegistry(itemKeys, targetName), {});
  }
  if (targetRecord[itemKeys]) {
    Object.entries(targetRecord[itemKeys]).forEach(([sourceItemId, targetItemId]) => {
      const itemRecord = window.$$DIRegistry[itemKeys][targetItemId];
      if (!itemRecord) {
        throw new Error(
          `Attempt to use an unregistered '${itemKey}' '${itemRecord}' inside component '${targetName}'`
        );
      }
      items[sourceItemId] = getSelf(itemRecord);
    }, {});
  }
  return items;
}

function getTargetName(descriptor, targetCategory) {
  const targetName = descriptor.name;
  if (!targetName) {
    throw new Error(`Attempt to inject ${targetCategory} without name in descriptor`);
  }
  return targetName;
}

function getTargetRecord(registry, targetName, targetCategory) {
  const targetRecord = registry[targetName];
  if (!targetRecord) {
    throw new Error(`Attempt to use an unregistered ${targetCategory} '${targetName}'`);
  }
  return targetRecord;
}

function prepareServices(descriptor, targetName) {
  let services = {};
  if (descriptor.services && descriptor.services.length) {
    services = descriptor.services.reduce((container, serviceId) => {
      const service = useService(serviceId);
      if (!service) {
        throw new Error(
          `Attempt to use an unregistered service '${serviceId}' inside component '${targetName}'`
        );
      }
      container[serviceId] = service;
      return container;
    }, {});
  }
  return services;
}

function prepareComponents(descriptor, targetRecord, targetName) {
  let components = {};
  if (targetRecord.$$cachedComponents) {
    components = targetRecord.$$cachedComponents;
  } else {
    components = prepareItems('component', descriptor, targetRecord, targetName);
    targetRecord.$$cachedComponents = components;
  }
  return components;
}

function prepareHooks(descriptor, targetRecord, targetName) {
  let hooks = {};
  if (targetRecord.$$cachedHooks) {
    hooks = targetRecord.$$cachedHooks;
  } else {
    hooks = prepareItems('hook', descriptor, targetRecord, targetName);
    targetRecord.$$cachedHooks = hooks;
  }
  return hooks;
}

// function prepareDialogs(descriptor, targetRecord, targetName) {
//   let dialogs = {};
//   if (targetRecord.$$cachedDialogs) {
//     dialogs = targetRecord.$$cachedDialogs;
//   } else {
//     dialogs = prepareItems('dialog', descriptor, targetRecord, targetName);
//     targetRecord.$$cachedDialogs = dialogs;
//   }
//   return dialogs;
// }

function prepareStyles(descriptor, targetRecord, registryStyles, targetName) {
  let styles = {};
  if (targetRecord.$$cachedStyles) {
    styles = targetRecord.$$cachedStyles;
  } else {
    const descriptorStyles = descriptor.styles;
    if (descriptorStyles === undefined) return styles;
    const targetRecordStyles = targetRecord.styles;
    descriptorStyles.forEach((styleModuleName) => {
      const style =
        (targetRecordStyles && targetRecordStyles[styleModuleName]) ||
        (registryStyles && registryStyles[styleModuleName]);
      if (!style) {
        throw new Error(
          `Attempt to use an unregistered style '${styleModuleName}' inside component '${targetName}'`
        );
      }
      styles[styleModuleName] = style;
    });
    targetRecord.$$cachedStyles = styles;
  }
  return styles;
}

export function injectedService(descriptor, fc) {
  const targetName = getTargetName(descriptor, 'service');
  return (params) => {
    const targetRecord = getTargetRecord(window.$$DIRegistry.services, targetName, 'service');
    const services = prepareServices(descriptor, targetName);
    const components = prepareComponents(descriptor, targetRecord, targetName);
    // const dialogs = prepareDialogs(descriptor, targetRecord, targetName);
    const hooks = prepareHooks(descriptor, targetRecord, targetName);
    const diSettings = targetRecord.settings || noDISettings;
    return fc({ ...params, services, hooks, components, diSettings });
  };
}

export function injectedHook(descriptor, fc) {
  const targetName = getTargetName(descriptor, 'hook');
  return (params) => {
    const targetRecord = getTargetRecord(window.$$DIRegistry.hooks, targetName, 'hook');
    const services = prepareServices(descriptor, targetName);
    const components = prepareComponents(descriptor, targetRecord, targetName);
    // const dialogs = prepareDialogs(descriptor, targetRecord, targetName);
    const hooks = prepareHooks(descriptor, targetRecord, targetName);
    const diSettings = targetRecord.settings || noDISettings;
    return fc({ ...params, services, hooks, components, diSettings });
  };
}

export function injectedComponent(descriptor, fc) {
  const targetName = getTargetName(descriptor, 'component');
  const injected = {
    [descriptor.name]: (params) => {
      const targetRecord = getTargetRecord(window.$$DIRegistry.components, targetName, 'component');
      const services = prepareServices(descriptor, targetName);
      const components = prepareComponents(descriptor, targetRecord, targetName);
      const hooks = prepareHooks(descriptor, targetRecord, targetName);
      const styles = prepareStyles(
        descriptor,
        targetRecord,
        window.$$DIRegistry.styles,
        targetName
      );
      const diSettings = targetRecord.settings || noDISettings;
      return fc({ ...params, services, hooks, components, styles, diSettings });
    },
  };
  return injected[descriptor.name];
}

// export function injectedDialog(descriptor, fc) {
//   const targetName = getTargetName(descriptor, 'dialog');
//   return (params) => {
//     const targetRecord = getTargetRecord(window.$$DIRegistry.dialogs, targetName, 'dialog');
//     const services = prepareServices(descriptor, targetName);
//     const components = prepareComponents(descriptor, targetRecord, targetName);
//     const dialogs = prepareDialogs(descriptor, targetRecord, targetName);
//     const hooks = prepareHooks(descriptor, targetRecord, targetName);
//     const styles = prepareStyles(descriptor, targetRecord, window.$$DIRegistry.styles, targetName);
//     return fc({ ...params, services, hooks, components, dialogs, styles });
//   };
// }

// function inject(params, descriptor, fc, registry) {
//   const targetName = descriptor.name;
//   if (!targetName) {
//     throw new Error(`Attempt to inject component without name in descriptor`);
//   }
//   const targetRecord = registry[targetName];
//   if (!targetRecord) {
//     throw new Error(`Attempt to use an unregistered component '${targetName}'`);
//   }

//   let services = {};
//   if (descriptor.services && descriptor.services.length) {
//     services = descriptor.services.reduce((container, serviceId) => {
//       const service = useService(serviceId);
//       if (!service) {
//         throw new Error(
//           `Attempt to use an unregistered service '${serviceId}' inside component '${targetName}'`
//         );
//       }
//       container[serviceId] = service;
//       return container;
//     }, {});
//   }

//   let components = {};
//   if (targetRecord.$$cachedComponents) {
//     components = targetRecord.$$cachedComponents;
//   } else {
//     components = prepareItems('component', descriptor, targetRecord, targetName);
//     targetRecord.$$cachedComponents = components;
//   }

//   let hooks = {};
//   if (targetRecord.$$cachedHooks) {
//     hooks = targetRecord.$$cachedHooks;
//   } else {
//     hooks = prepareItems('hook', descriptor, targetRecord, targetName);
//     targetRecord.$$cachedHooks = hooks;
//   }
//   return fc({ ...params, ...services, ...components, ...hooks });
// }
